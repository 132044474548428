.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 80%;
    width: 50%;
    background: linear-gradient(to bottom, #3498db, #2980b9);
    border: 1vh solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    color: #fff;
    border-radius: 4%;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 3%;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .startButton {
    font-size: 2vw;
    padding: 3% 5%;
    margin: 20%;
    cursor: pointer;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 3%;
    transition: background-color 0.3s;
  }
  
  .startButton:hover {
    background-color: #c0392b;
  }
  
  .gameInfo {
    font-size: 2vw;
    margin-bottom: 6%;
  }
  
  .gameArea {
    width: 60%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .balloon {
    width: 70%;
    height: 96%;
    background-color: #e74c3c;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4%;
    animation: float 2s ease-in-out infinite;
    margin-top: 15%;
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
  .problem {
    font-size: 2.5vw;
    font-weight: bold;
    margin-bottom: 5%;
    color: #fff;
  }
  
  .option {
    font-size: 2vw;
    margin: 3%;
    padding: 5%;
    width: 60%;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .option:hover {
    background-color: #2980b9;
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }