.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  width: 50%;
  max-width: 50%;
  height: 60%;
  max-height: 60%;
  background-color: rgba(139, 69, 19, 0.8);
  border: calc(0.4vw + 0.4vh) solid #5A3310;
  border-radius: calc(0.5vw + 0.5vh);
  padding: 3%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.6vmin;
  overflow-y: auto;
}

.modalContent h2 {
  font-size: 2.4vmin;
  margin-bottom: 3%;
  text-align: center;
}

.formGroup {
  margin-bottom: 2%;
}

.formGroup label {
  display: block;
  margin-bottom: 3%;
  font-size: 1.8vmin;
}

.formGroup input {
  width: 100%;
  padding: 1%;
  box-sizing: border-box;
  font-size: 1.6vmin;
  background-color: #5A3310;
  border: 1px solid #8B4513;
  color: white;
  border-radius: 0.5vmin;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.characterButton {
  flex: 1;
  padding: 1%;
  background-color: #8B4513;
  border: 1px solid #5A3310;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.6vmin;
  margin: 0 1%;
  border-radius: 0.5vmin;
}

.characterButton:hover, .characterButton.active {
  background-color: #5A3310;
}

.submitButton {
  background-color: #5A3310;
  color: white;
  border: none;
  border-radius: 0.5vmin;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.6vmin;
  padding: 1%;
  margin-top: auto;
  width: 100%;
}

.submitButton:hover {
  background-color: #8B4513;
}

/* Styling the scrollbar */
.modalContent::-webkit-scrollbar {
  width: 1vmin;
}

.modalContent::-webkit-scrollbar-track {
  background: #5A3310;
}

.modalContent::-webkit-scrollbar-thumb {
  background: #8B4513;
  border-radius: 0.5vmin;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: #704012;
}