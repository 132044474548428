/* ImagePreloaderFunc.module.css */
.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  }
  
  .loadingBox {
    background-color: #3e40c5;
    border: calc(0.2vw + 0.2vh) solid #34105a;
    color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0.3vmin 0.3vmin 0.5vmin rgba(0, 0, 0, 0.5);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .title {
    font-size: calc(1vw + 1vh);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .progressBarContainer {
    width: 100%;
    height: 20px;
    background-color: #34105a;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .progressBar {
    height: 100%;
    background-color: #ffffff;
    transition: width 0.3s ease-out;
  }
  
  .progressText {
    font-size: calc(0.8vw + 0.8vh);
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .timeElapsed {
    font-size: calc(0.6vw + 0.6vh);
  }
  
  .preparingText {
    margin-top: 1rem;
    font-size: calc(0.5vw + 0.5vh);
    font-style: italic;
  }