
.mysticTextContainer {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mysticText {
  font-family: 'Cinzel', serif;
  font-size: clamp(4.5vw, 3vw + 3vh, 4.5vw);
  color: #e0e0e0;
  text-shadow: 0 0 10px rgba(53, 73, 206, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.293);
  text-align: center;
  max-width: 90%; /* Prevent text from overflowing on very small screens */
}