
.button1 {
    top: calc(1.5vw + 1.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  .button2 {
    
    background-color: green;
  }
  
  .button3 {
    top: calc(7.5vw + 7.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  .button4 {
    top: calc(10.5vw + 10.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  .button {
    position: absolute;
    background-color: #8B4513;
    border: calc(0.2vw + 0.2vh) solid #5A3310;
    color: white;
    width: calc(2.5vw + 2.5vh);
    height: calc(2.5vw + 2.5vh);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.5vw + 0.5vh);
    text-align: center;
    transition: transform 0.2s;
  }
  
  .button:hover {
    transform: scale(1.1);
  }


  .button2 {
    top: 30%;
    left: 40%;
    width: 20%;
    height: 30%;
    background: transparent;
    border: none;
    position: absolute;
    cursor: pointer;
    
  }
  
  .modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal, .successModal {
    background-color: #f0e6d2;
    border: 2px solid #c8aa6e;
    border-radius: 10px;
    padding: 2%;
    width: 60%;
    max-width: 60%;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
    position: relative;
  }
  
  .successModal {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .modal h2, .successModal h2 {
    color: #463714;
    margin-bottom: 2%;
    font-size: 2vmin;
  }
  
  .modal p, .successModal p {
    font-size: 1.6vmin;
  }
  
  .modalButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
  }
  
  .modalButton {
    padding: 1.5% 3%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    font-size: 1.6vmin;
  }
  
  .payButton {
    background-color: #1e9e6b;
    color: white;
  }
  
  .payButton:hover {
    background-color: #16724e;
  }
  
  .noButton {
    background-color: #e74c3c;
    color: white;
  }
  
  .noButton:hover {
    background-color: #c0392b;
  }
  
  .goldInfo {
    color: #463714;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: 1.6vmin;
  }
  
  .errorMessageContainer {
    height: 20px;
    margin-bottom: 1%;
  }
  
  .errorMessage {
    color: #e74c3c;
    font-weight: bold;
    font-size: 1.6vmin;
  }
  
  @keyframes swirl-fade-in {
    0% {
      transform: rotate(0deg) scale(0);
      opacity: 0;
    }
    50% {
      transform: rotate(180deg) scale(1.1);
      opacity: 0.5;
    }
    100% {
      transform: rotate(360deg) scale(1);
      opacity: 1;
    }
  }
  
  .successModal {
    animation: swirl-fade-in 1s ease-out;
  }
  
  .successModal h2 {
    opacity: 0;
    animation: fade-in 0.5s ease-out 0.5s forwards;
  }
  
  .successModal p {
    opacity: 0;
    animation: fade-in 0.5s ease-out 0.7s forwards;
  }
  
  .recipeNameReveal {
    color: #1e90ff;
    font-size: 1.8vmin;
    margin-bottom: 3%;
    opacity: 0;
    animation: fade-in 0.5s ease-out 0.9s forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .okButton {
    background-color: #3498db;
    color: white;
    padding: 1.5% 3%;
    border: none;
    border-radius: 5px;
    font-size: 1.6vmin;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    opacity: 0;
    animation: fade-in 0.5s ease-out 1.1s forwards;
    margin-top: 2%;
  }
  
  .okButton:hover {
    background-color: #2980b9;
    transform: scale(1.05);
  }
  
  .recipeItems {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2%;
    width: 100%;
    max-height: 60%;
  }
  
  .recipeItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1% 0;
    padding: 1%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    animation: fade-in 0.5s ease-out forwards;
    opacity: 0;
  }
  
  .recipeItem:nth-child(1) { animation-delay: 1.1s; }
  .recipeItem:nth-child(2) { animation-delay: 1.2s; }
  .recipeItem:nth-child(3) { animation-delay: 1.3s; }
  .recipeItem:nth-child(4) { animation-delay: 1.4s; }
  
  .itemImage {
    width: 10%;
    height: auto;
    object-fit: contain;
    margin-right: 2%;
  }
  
  .itemName {
    text-align: center;
    margin: 0;
    font-size: 1.6vmin;
    color: #463714;
    flex-grow: 0;
    width: 60%;
  }
  
  @media (max-aspect-ratio: 16/9) {
    .modal, .successModal {
      width: 60%;
      max-width: 60%;
    }
  
    .modal h2, .successModal h2 {
      font-size: 2.0vmin;
    }
  
    .modal p, .successModal p {
      font-size: 1.4vmin;
    }
  
    .modalButton, .okButton {
      font-size: 1.8vmin;
    }
  
    .recipeNameReveal {
      font-size: 2.0vmin;
    }
  
    .itemName {
      font-size: 1.8vmin;
    }
  }