.modalBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #3e40c5;
  border: calc(0.2vw + 0.2vh) solid #34105a;
  padding: 2%;
  border-radius: 1vmin;
  text-align: center;
  color: white;
  width: 50%;
  max-width: 50%;
  font-size: 1.6vmin;
}

.buttonContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
}

.confirmButton, .cancelButton {
  padding: 1% 2%;
  border: none;
  border-radius: 0.5vmin;
  cursor: pointer;
  font-size: 1.6vmin;
  transition: transform 0.2s;
}

.confirmButton {
  background-color: #4CAF50;
  color: white;
}

.cancelButton {
  background-color: #f44336;
  color: white;
}

.confirmButton:hover, .cancelButton:hover {
  transform: scale(1.1);
}