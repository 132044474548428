.puzzleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  background: linear-gradient(to bottom, #00008b, #000);
  border: 5px solid #fff;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #fff;
  max-width: 800px;
  width: 90%;
}

.puzzleContainer.visible {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.title {
  font-size: 3vw;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}

.instructions {
  font-size: 2vw;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.gameContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cipherTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.cipherText {
  font-size: 3vw;
  color: #fff;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input {
  font-size: 2vw;
  padding: 5px;
  margin-bottom: 10px;
  width: 80%;
}

.button {
  font-size: 2vw;
  padding: 5px 10px;
  cursor: pointer;
  width: 50%;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}

.gameInfo {
  text-align: center;
  font-size: 2vw;
  font-weight: bold;
  margin-top: 20px;
  color: #fff;
}

.startButton {
  font-size: 2vw;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px auto;
  display: block;
}

.startButton:hover {
  background-color: #45a049;
}

.correct, .incorrect {
  font-size: 2vw;
  text-align: center;
  margin-top: 20px;
}

.correct {
  color: #4CAF50;
}

.incorrect {
  color: #f44336;
}

.symbolTable {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  color: #fff;
  margin-top: 20px;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;
}

.symbolPair {
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.letter {
  font-weight: bold;
}

.symbol {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .puzzleContainer {
    padding: 10px;
  }

  .title {
    font-size: 5vw;
  }

  .instructions {
    font-size: 3vw;
  }

  .cipherText {
    font-size: 4vw;
  }

  .input, .button, .startButton, .gameInfo, .symbolPair {
    font-size: 3vw;
  }

  .symbolTable {
    grid-template-columns: repeat(3, 1fr);
  }
}