.homeButton {
  top: calc(1.5vw + 1.5vh);
  left: calc(1.5vw + 1.5vh);
  position: absolute;
  background-color: #3e40c5;
  border: calc(0.2vw + 0.2vh) solid #34105a;
  color: white;
  width: calc(2.5vw + 2.5vh);
  height: calc(2.5vw + 2.5vh);
  box-shadow: 0.3vmin 0.3vmin 0.5vmin rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5vw + 0.5vh);
  text-align: center;
  transition: transform 0.2s;
}

.homeButton:hover {
  transform: scale(1.1);
}

.findArtifactButton {
  position: absolute;
  top: calc(4.5vw + 4.5vh);
  left: calc(1.5vw + 1.5vh);
  background-color: #3e40c5;
  border: calc(0.2vw + 0.2vh) solid #34105a;
  color: white;
  width: calc(2.5vw + 2.5vh);
  height: calc(2.5vw + 2.5vh);
  box-shadow: 0.3vmin 0.3vmin 0.5vmin rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5vw + 0.5vh);
  text-align: center;
  transition: transform 0.2s;
}

.findArtifactButton:hover {
  transform: scale(1.1);
}

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #f0e6d2;
  border: calc(0.2vw + 0.2vh) solid #c8aa6e;
  border-radius: 1vmin;
  padding: 2%;
  width: 50%;
  max-width: 50%;
  text-align: center;
}

.modal h2 {
  color: #463714;
  margin-bottom: 2%;
  font-size: 2.4vmin;
}

.modalButtons {
  display: flex;
  justify-content: space-around;
}

.modalButton {
  padding: 1% 2%;
  border: none;
  border-radius: 0.5vmin;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 1.6vmin;
}

.payButton {
  background-color: #1e9e6b;
  color: white;
}

.payButton:hover {
  background-color: #16724e;
}

.noButton {
  background-color: #e74c3c;
  color: white;
}

.noButton:hover {
  background-color: #c0392b;
}

.goldInfo {
  color: #463714;
  font-weight: bold;
  margin-bottom: 2%;
  font-size: 1.6vmin;
}

.errorMessageContainer {
  height: 2vmin;
  margin-bottom: 1%;
}

.errorMessage {
  color: #e74c3c;
  font-weight: bold;
  font-size: 1.6vmin;
}

@keyframes swirl-fade-in {
  0% {
    transform: rotate(0deg) scale(0);
    opacity: 0;
  }
  50% {
    transform: rotate(180deg) scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}

.successModal {
  background-color: rgba(240, 230, 210, 0.9);
  border: calc(0.2vw + 0.2vh) solid #c8aa6e;
  border-radius: 1vmin;
  padding: 2%;
  width: 50%;
  max-width: 50%;
  height: 90%;
  max-height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
  animation: swirl-fade-in 1s ease-out;
}

.successModal h2 {
  color: #463714;
  font-size: 2.4vmin;
  margin-bottom: 2%;
  opacity: 0;
  animation: fade-in 0.5s ease-out 0.5s forwards;
}

.successModal p {
  color: #6d5d41;
  font-size: 1.8vmin;
  margin-bottom: 3%;
  opacity: 0;
  animation: fade-in 0.5s ease-out 0.7s forwards;
}

.artifactNameReveal {
  color: #1e90ff;
  font-size: 2.2vmin;
  margin-bottom: 3%;
  opacity: 0;
  animation: fade-in 0.5s ease-out 0.9s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(2vmin);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.okButton {
  background-color: #3498db;
  color: white;
  padding: 1% 2%;
  border: none;
  border-radius: 0.5vmin;
  font-size: 1.6vmin;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  opacity: 0;
  animation: fade-in 0.5s ease-out 1.1s forwards;
}

.okButton:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}