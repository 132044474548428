.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #66cc66, #339933);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .startButton {
    font-size: 2vw;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .startButton:hover {
    background-color: #ff8533;
  }
  
  .gameInfo {
    font-size: 2vw;
    margin-bottom: 20px;
  }
  
  .gameBoard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .hole {
    width: 100px;
    height: 100px;
    background-color: #663300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    cursor: pointer;
  }
  
  .mole {
    font-size: 4em;
    animation: popUp 0.5s ease;
  }
  
  @keyframes popUp {
    0% { transform: translateY(100%); }
    100% { transform: translateY(0); }
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .gemstoneImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    
  }
  .hole {
    width: 100px;
    height: 100px;
    background-color: #8B4513;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .mole {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: bottom 0.1s;
    pointer-events: none;
  }
  
  .active .mole {
    bottom: 0;
  }
  
  .gemstoneImage {
    width: 80%;
    height: 80%;
    object-fit: contain;
    position: absolute;
    bottom: 10%;
    left: 10%;
    transition: transform 0.1s;
  }
  
  .active .gemstoneImage {
    transform: translateY(0);
  }
  
  .hole:not(.active) .gemstoneImage {
    transform: translateY(100%);
  }
  
  @media (max-width: 500px) {
    .gameBoard {
      max-width: 300px;
    }
  
    .hole {
      width: 80px;
      height: 80px;
    }
  }