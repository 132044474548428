
.homeButton {
  top: calc(1.5vw + 1.5vh);
  left: calc(1.5vw + 1.5vh)
}



  .homeButton {
    position: absolute;
    background-color: #3e40c5; /* Rustic brown color */
    border: 4px solid #34105a; /* Darker rustic brown border */
    color: white;
    width: calc(2.5vw + 2.5vh); /* Scales with viewport size */
    height: calc(2.5vw + 2.5vh); /* Scales with viewport size */
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.5vw + 0.5vh); /* Scales with viewport size */
    text-align: center;
    transition: transform 0.2s;
  }

.homeButton:hover {
  transform: scale(1.1);
}

.invisibleButton {
  background: transparent;
  border: none;
  position: absolute;
  cursor: pointer;
}

.hoverText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(62, 64, 197, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  font-size: calc(0.8vw + 0.8vh);
}

.invisibleButton:hover .hoverText {
  opacity: 1;
}

.button1 {
  top: 24%;
  left: 40%;
  width: 8%;
  height: 5%;
}

.button2 {
  top: 56%;
  left: 44%;
  width: 10.5%;
  height: 16.5%;
}

.button3 {
  top: 62%;
  left: 70%;
  width: 6%;
  height: 10%; 
}

.button4 {
  top: 66%;
  left: 13%;
  width: 6%;
  height: 10%; 
}

.button5 {
  top: 36%;
  left: 63%;
  width: 6%;
  height: 10%; 
}

.button6 {
  top: 15%;
  left: 72%;
  width: 10%;
  height: 12%; 
}

.button7 {
  top: 37%;
  left: 37%;
  width: 6%;
  height: 8%; 
}

.button8 {
  top: 17%;
  left: 40%;
  width: 8%;
  height: 5%; 
}

.button9 {
  top: 9%;
  left: 40%;
  width: 8%;
  height: 5%;  

}

.button10 {
  top: 85%;
  left: 68%;
  width: 6%;
  height: 8%; 
}