.pageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 98vh;
    min-width: 98vw;
    background-color: #000;
  }
  
  .pageContainer {
    position: relative;
    max-width: 1792px;
    aspect-ratio: 1792 / 1024;
    height: 96%;
    width: 98%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }