
  
  .button1 {
    top: calc(1.5vw + 1.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  .button2 {
    top: calc(4.5vw + 4.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  .button3 {
    top: calc(7.5vw + 7.5vh);
    left: calc(1.5vw + 1.5vh)
  }

  .button4 {
    top: calc(10.5vw + 10.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
    .button {
      position: absolute;
      background-color: #8B4513; /* Rustic brown color */
      border: calc(0.2vw + 0.2vh) solid #5A3310; /* Darker rustic brown border */
      color: white;
      width: calc(2.5vw + 2.5vh); /* Scales with viewport size */
      height: calc(2.5vw + 2.5vh); /* Scales with viewport size */
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(0.5vw + 0.5vh); /* Scales with viewport size */
      text-align: center;
      transition: transform 0.2s;
    }

  .button:hover {
    transform: scale(1.1);
  }
  