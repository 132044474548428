
    
  .invisibleButton {
    background: transparent;
    border: none;
    position: absolute;
    cursor: pointer;
  }

  .button5 {
    top: 22%;
    left: 47%;
    width: 8%;
    height: 8%; 
  }

  
  
   