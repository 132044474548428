/* PuzzleGraph.module.css */
.graphWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    box-sizing: border-box;
  }
  
  .graphTitle {
    text-align: center;
    color: white;
    margin-bottom: 1%;
    font-size: 2.5vw;
  }
  
  .graphContainer {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1vw;
    padding: 8%;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  
  .benchmarkLabels {
    width: 15%;
    position: relative;
  }
  
  .graph {
    height: 100%;
    width: 85%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
  }
  
  .bar {
    width: 8%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  
  .barFill {
    width: 100%;
    background-color: #3e40c5;
    position: relative;
    transition: height 0.5s ease-in-out;
  }
  
  .barLabel {
    position: absolute;
    top: -2.5vh;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.2vw;
    background-color: rgba(52, 16, 90, 0.7);
    padding: 0.2vw 0.5vw;
    border-radius: 0.3vw;
  }
  
  .puzzleName {
    position: absolute;
    bottom: -3vh;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    color: white;
    font-size: 0.8vw;
    text-align: left;
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.2vw 0.5vw;
  }
  
  .benchmark {
    position: absolute;
    width: 100%;
    border-top: 0.2vw dashed;
    z-index: 1;
  }
  
  .benchmarkLabel {
    position: absolute;
    left: 0;
    transform: translateY(50%);
    color: black;
    font-size: 1.2vw;
    padding: 0.2vw 0.5vw;
    border-radius: 0.3vw;
    white-space: nowrap;
  }
  
  .verticalLine, .horizontalLine {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 0;
  }
  
  .verticalLine {
    width: 0.1vw;
    height: 100%;
    bottom: 0;
  }
  
  .horizontalLine {
    width: 100%;
    height: 0.1vw;
    left: 0;
  }

  .levelInfo {
    text-align: center;
    color: white;
    margin-bottom: 1%;
    font-size: 1.0vw;
  }