.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
  .modalContent {
    background-color: rgba(53, 73, 206, 0.9);
    padding: 4%;
    border-radius: 3%;
    text-align: center;
    color: white;
    width: 50%; /* Set a specific width */
    max-width: 60%; /* Limit the maximum width */
    font-size: 2vw;
    border:1vh solid #34105a;
  }
  
  .modalContent h2 {
    margin-top: 0;
    font-size: 3vw;
  }
  
  .modalContent button {
    margin-top: 5%;
    padding: 3% 5%;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3%;
    cursor: pointer;
    font-size: 2vw;
  }
  
  .modalContent button:hover {
    background-color: #45a049;
  }
  
  .prizeContainer {
    display: flex;
    justify-content: center;
    gap: 4%;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 45%; /* Set a specific width */
    border:5px solid #34105a;
  }
  
  .prizeImage {
    width: 100%; /* Make image fill its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain;
    max-height: 70%; /* Limit maximum height */
  }
  
  .imageContainer{
    margin-top: 2%;
    font-weight: bold;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }

  .imageText {
    text-align: center;
    margin-top: 5px;
  }
  
  .imageText p {
    margin: 0;
  }