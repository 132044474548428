
.invisibleButton {
  background: transparent;
  border: none;
  position: absolute;
  cursor: pointer;
}

.button1 {
  top: 22.5%;
  left: 36.5%;
  width: 12.5%;
  height: 20.5%;
}


.button2 {
  top: 70.5%;
  left: 69.5%;
  width: 10.5%;
  height: 16.5%;
}

.button3 {
  top: 54.5%;
  left: 43.5%;
  width: 10.5%;
  height: 16.5%;
}

.button4 {
  top: 64.5%;
  left: 57.5%;
  width: 3.5%;
  height: 6.5%;
}

.button5 {
  top: 61.5%;
  left: 79.5%;
  width: 5.5%;
  height: 8.5%;
}

.hoverText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(139, 69, 19, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.invisibleButton:hover .hoverText {
  opacity: 1;
}

.resetButton {
  position: absolute;
  top: calc(1.5vw + 1.5vh);
  right: calc(1.5vw + 1.5vh);
  background-color: #3e40c5;
  border: calc(0.2vw + 0.2vh) solid #34105a;
  color: white;
  width: calc(2.5vw + 2.5vh);
  height: calc(2.5vw + 2.5vh);
  box-shadow: 0.3vmin 0.3vmin 0.5vmin rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5vw + 0.5vh);
  text-align: center;
  transition: transform 0.2s;
}

.resetButton svg {
  display: block;
  margin: auto;
}

.resetButton:hover {
  transform: scale(1.1);
}