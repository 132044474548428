.gameContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 90%;
  max-width: 1200px;
  max-height: 800px;
  background: linear-gradient(to bottom, #4b0082, #8a2be2);
  border: 0.5vw solid #fff;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  color: #fff;
  border-radius: 2vw;
  overflow: hidden;
}

.gameContainer.visible {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.title {
  font-size: 2.5vw;
  margin-bottom: 1%;
}

.gameContent {
  display: flex;
  width: 90%;
  height: 95%;
  justify-content: space-between;
}

.gameArea {
  width: 65%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-right: 2%;
}

.instructions {
  font-size: 1.2vw;
  margin-bottom: 1%;
  text-align: center;
}

.sequenceGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5vw;
  padding: 0.5vw;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1vw;
  width: 70%;
  aspect-ratio: 1 / 1;
  height : 60%;
  max-height: 50%;
}

.sequenceItem {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border: 0.15vw solid white;
  border-radius: 0.5vw;
  overflow: hidden;
}

.sequenceImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.resultDot {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 10%;
  height: 10%;
  border-radius: 50%;
}

.correct {
  background-color: green;
}

.incorrect {
  background-color: red;
}

.gameInfo {
  font-size: 1.5vw;
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.startButton {
  font-size: 1.2vw;
  padding: 1% 2%;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5vw;
  transition: background-color 0.3s;
  margin-top: 1%;
}

.startButton:hover {
  background-color: #45a049;
}

.imageTable {
  width: 30%;
  height: 70%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5vw;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1vw;
  padding: 0.5%;
  margin-top: 7%;
  align-self: flex-start;
  overflow-y: auto;
}

.tableImage {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 0.15vw solid transparent;
  border-radius: 0.5vw;
  transition: border-color 0.3s;
}

.tableImage:hover {
  border-color: white;
}

.gameOverMessage {
  font-size: 1.5vw;
  color: #FFD700;
  text-align: center;
  margin-top: 1%;
  font-weight: bold;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0,0,0,0.5);
}

@media (max-width: 768px) {
  .gameContent {
    flex-direction: column;
  }

  .gameArea, .imageTable {
    width: 100%;
    height: auto;
  }

  .sequenceGrid {
    aspect-ratio: auto;
    height: 40vh;
  }

  .imageTable {
    margin-top: 2%;
    height: 30vh;
  }

  .title {
    font-size: 4vw;
  }

  .instructions, .gameInfo {
    font-size: 2.5vw;
  }

  .startButton {
    font-size: 2.5vw;
  }
}