.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #9b59b6, #8e44ad);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .startButton {
    font-size: 2vw;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .startButton:hover {
    background-color: #c0392b;
  }
  
  .gameInfo {
    font-size: 2vw;
    margin-bottom: 20px;
  }
  
  .shapeDisplay {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .shape {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    border-radius: 50%;
  }
  
  .square {
    border-radius: 10px;
  }
  
  .triangle {
    width: 0;
    height: 0;
    background-color: transparent !important;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid;
  }
  
  .star {
    background-color: transparent !important;
    color: inherit;
    font-size: 100px;
    line-height: 100px;
  }
  
  .hexagon {
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  }
  
  .diamond {
    transform: rotate(45deg);
  }
  
  .pentagon {
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  }
  
  .octagon {
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    max-width: 400px;
  }
  
  .shapeButton {
    font-size: 1.2vw;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .shapeButton:hover {
    background-color: #2980b9;
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }