/* PotionGrid.module.css */
.gridWrapper {
    position: absolute;
    top: 10%;
    left: 15%;
    width: 70%;
    height: 85%;
    background-color: rgba(139, 69, 19, 0.8);
    border: calc(0.4vw + 0.4vh) solid #5A3310;
    border-radius: calc(0.5vw + 0.5vh);
    opacity: 0;
    transform: translateY(4%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    overflow: hidden;
    padding: 3%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .tabContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%
  }
  
  .tab {
    flex: 1;
    padding: 2%;
    background-color: #8B4513;
    border: 0.5% solid #5A3310;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.5vw;;
  }
  
  .tab:hover, .activeTab {
    background-color: #5A3310;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 4%;
    flex-grow: 1;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  .gridItem {
    display: flex;
    flex-direction: column;
  }
  
  .imageContainer {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* This creates a 1:1 aspect ratio */
    background: linear-gradient(135deg, #8B4513, #5A3310);
    border-radius: 2%;
    overflow: hidden;
  }
  
  .itemImage {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    object-fit: contain;
  }
  
  .itemName {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 55, 55);
    color: white;
    font-size: 1vw;
    text-align: center;
    padding: 6%;
    box-sizing: border-box;
  }

  .itemNameAll {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(55, 55, 55);
    color: white;
    font-size: 1vw;
    text-align: center;
    padding: 6%;
    box-sizing: border-box;
  }
  
  /* Styling the scrollbar */
  .gridContainer::-webkit-scrollbar {
    width: 3%;
  }
  
  .gridContainer::-webkit-scrollbar-track {
    background: #5A3310;
  }
  
  .gridContainer::-webkit-scrollbar-thumb {
    background: #8B4513;
    border-radius: 1%;
  }
  
  .gridContainer::-webkit-scrollbar-thumb:hover {
    background: #704012;
  }