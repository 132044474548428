.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #4b0082, #8a2be2);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
    width: 80%;
    max-width: 1000px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 20px;
  }
  
  .gameContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .gameArea {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .instructions {
    font-size: 1.5vw;
    margin-bottom: 20px;
  }
  
  .romanDisplay {
    font-size: 5vw;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .input {
    font-size: 2vw;
    padding: 10px;
    width: 100px;
    margin-right: 10px;
  }
  
  .submitButton, .startButton {
    font-size: 1.5vw;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }
  
  .gameInfo {
    font-size: 1.5vw;
    margin-top: 20px;
  }
  
  .chart {
    width: 55%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .chart h3 {
    font-size: 2vw;
    margin-bottom: 10px;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: rgba(75, 0, 130, 0.9);
    padding: 10px 0;
  }
  
  .chartContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    
  }
  
  .chartItem {
    font-size: 1vw;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .resetButton {
    font-size: 1.5vw;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resetButton:hover {
    background-color: #45a049;
  }