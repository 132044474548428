.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #006994, #00BFFF);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .gameBoard {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .bubble {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    border-radius: 50%;
    transition: transform 0.3s;
    background-color: #f0f0f0;
    color: #000;
  }
  
  .bubble:hover {
    transform: scale(1.1);
  }
  
  .gameInfo {
    font-size: 1.5vw;
    margin-bottom: 10px;
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .resetButton, .startButton {
    font-size: 1.5vw;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resetButton:hover, .startButton{
    background-color: #45a049;
  }

  .bubble.correct {
    background-color: white !important;
  }
  
  .bubble.incorrect {
    background-color: white !important;
  }
  
  .tick {
    color: green;
    font-size: 24px;
  }
  
  .cross {
    color: red;
    font-size: 24px;
  }