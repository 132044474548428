
  
  .homeButton {
    top: calc(1.5vw + 1.5vh);
    left: calc(1.5vw + 1.5vh)
  }

  .mapButton {
    top: calc(4.5vw + 4.5vh);
    left: calc(1.5vw + 1.5vh)
  }
  
  
  
    .button {
      position: absolute;
      background-color: #3e40c5; /* Rustic brown color */
      border: 4px solid #34105a; /* Darker rustic brown border */
      color: white;
      width: calc(2.5vw + 2.5vh); /* Scales with viewport size */
      height: calc(2.5vw + 2.5vh); /* Scales with viewport size */
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(0.5vw + 0.5vh); /* Scales with viewport size */
      text-align: center;
      transition: transform 0.2s;
    }

  .button:hover {
    transform: scale(1.1);
  }