.homeButton {
  position: absolute;
  top: 2%;
  left: 2%;
  background-color: #3e40c5;
  border: 4px solid #34105a;
  color: white;
  width: calc(2.5vw + 2.5vh);
  height: calc(2.5vw + 2.5vh);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  text-align: center;
  transition: transform 0.2s;
}

.homeButton:hover {
  transform: scale(1.1);
}

.leftInfo, .rightInfo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 1.5%;
  border-radius: 10px;
  color: white;
  width: 11%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #34105a;
  box-shadow: 0 0 10px rgba(62, 64, 197, 0.5);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.leftInfo {
  left: 2%;
}

.rightInfo {
  right: 2%;
}

.playerInfoContainer {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5%;
}

.playerInfoContainer h2, .playerInfoContainer h3 {
  font-size: 1vw;
  margin: 2% 0;
}

.levelContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressRing {
  width: 100%;
  height: 100%;
  max-width: 120px;
  max-height: 120px;
}

.progressRingCircle {
  transition: stroke-dashoffset 0.35s;
}

.progressRingCircle.animate {
  animation: fillAnimation 1s ease-out forwards;
}

@keyframes fillAnimation {
  from { 
    stroke-dashoffset: var(--circumference, 283);
  }
  to { 
    stroke-dashoffset: var(--stroke-dashoffset, 0);
  }
}

.levelText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1vw;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5%;
}

.bottomNav {
  position: absolute;
  bottom: 2%;
  left: 2%;
  right: 4%;
  display: flex;
  justify-content: space-between;
}

.leftItems, .rightItems {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18%;
}

.itemImage {
  width: 100%;
  padding-bottom: 100%;
  background-color: rgba(62, 64, 197, 0.7);
  border: 2px solid #34105a;
  border-radius: 10%;
  box-shadow: 0 0 10px rgba(62, 64, 197, 0.5);
  transition: background-color 0.3s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.itemContainer:hover .itemImage {
  background-color: rgba(110, 111, 242, 0.7);
}

.itemInfoContainer {
  width: 100%;
  text-align: center;
  margin-top: 5%;
}

.itemName {
  color: white;
  font-size: 0.75vw;
  margin-bottom: 2%;
}

.itemCount {
  color: white;
  font-size: 0.75vw;
}

.claimRewardButton {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #ffd700;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.claimRewardButton:hover {
  background-color: #ffcc00;
}
