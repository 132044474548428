.contentContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.startButton {
  background-color: #3e40c5;
  border: 4px solid #34105a;
  border-radius: 10px;
  color: white;
  padding: 1rem 2rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.startButton:hover {
  transform: scale(1.1);
}

.loadingScreen {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.loadingContent {
  text-align: center;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.loadingText {
  color: white;
  font-size: 1.2rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these styles for MysticText and StartJourneyText components */
.mysticText, .startJourneyText {
  font-size: clamp(1.5rem, 3vw, 3rem);
  margin-bottom: 1rem;
}