.button1 {
  top: calc(1.5vw + 1.5vh);
  left: calc(1.5vw + 1.5vh);
}

.button2 {
  top: calc(4.5vw + 4.5vh);
  left: calc(1.5vw + 1.5vh);
}

.button3 {
  top: calc(7.5vw + 7.5vh);
  left: calc(1.5vw + 1.5vh);
}

.button4 {
  top: calc(10.5vw + 10.5vh);
  left: calc(1.5vw + 1.5vh);
}



.button {
  position: absolute;
  background-color: #8B4513;
  border: calc(0.2vw + 0.2vh) solid #5A3310;
  color: white;
  width: calc(2.5vw + 2.5vh);
  height: calc(2.5vw + 2.5vh);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5vw + 0.5vh);
  text-align: center;
  transition: transform 0.2s;
}
.button5 {
  top: 64%;
  left: 46%;
  width: 15%;
  height: 20%;
  opacity: 0;
  cursor: pointer;
}


.button:hover {
  transform: scale(1.1);
}

.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.successModal {
  background-color: rgba(240, 230, 210, 0.9);
  border: 2px solid #c8aa6e;
  border-radius: 10px;
  padding: 2%;
  width: 50%;
  max-width: 50%;
  height: 90%;
  max-height: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

.recipeNumber {
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 1.4vmin;
  color: #463714;
}

.recipeNameReveal {
  color: #1e90ff;
  font-size: 2vmin;
  margin-bottom: 2%;
}

.recipeItems {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 2%;
  width: 100%;
  max-height: 60%;
}

.recipeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1% 0;
  padding: 1%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.itemImage {
  width: 4vmin;
  height: 4vmin;
  object-fit: contain;
  margin-right: 1.5vmin;
}

.itemName {
  text-align: center;
  margin: 0;
  font-size: 1.6vmin;
  color: #463714;
  flex-grow: 0;
  width: 60%;
}

.checkmark {
  color: #2ecc71;
  font-weight: bold;
}

.cross {
  color: #e74c3c;
  font-weight: bold;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2%;
}

.navButton, .okButton, .createButton, .viewButton {
  background-color: #3498db;
  color: white;
  padding: 1% 2%;
  border: none;
  border-radius: 5px;
  font-size: 1.6vmin;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.navButton:hover, .okButton:hover, .createButton:hover, .viewButton:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.okButton {
  margin-top: 2%;
}

.messageContainer {
  height: 3%;
  margin: 2% 0;
}

.message {
  color: #463714;
  font-weight: bold;
  font-size: 1.6vmin;
}

.createButton {
  background-color: #4CAF50;
  margin-right: 1%;
  
}

.createButton:hover {
  background-color: #45a049;
}

.createButton:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.recipeImage {
  margin: 2% 0;
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recipeImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.recipeDescription {
  margin-bottom: 1%;
  font-style: italic;
  font-size: 1.6vmin;
  max-width: 90%;
  overflow-wrap: break-word;
}

.imagePlaceholder {
  width: 20%;
  height: 0;
  padding-bottom: 20%;
  background-color: #ddd;
  margin: 0 auto;
  border-radius: 10px;
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
  font-size: 1.6vmin;
  
}

.checkboxLabel {
  margin-right: 1.5vmin;
  display: flex;
  align-items: center;
  font-size: 1.6vmin;
  white-space: nowrap;
}

.checkboxLabel input {
  margin-right: 0.5vmin;
  width: 1.6vmin;
  height: 1.6vmin;
}

.noRecipesMessage {
  font-size: 1.8vmin;
  color: #666;
  text-align: center;
  margin: 2% 0;
}

h2 {
  font-size: 2.0 vmin;
}
