.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 98vh;
  min-width: 98vw;
  background-color: #000;
}

@media (max-aspect-ratio: 16/9) {
  .game-container {
    width: auto;
    height: 100%;
  }
}

@media (min-aspect-ratio: 16/9) {
  .game-container {
    width: 100%;
    height: auto;
  }
}