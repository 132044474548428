
    
  .homeButton {
    top: calc(1.5vw + 1.5vh);
    left: calc(1.5vw + 1.5vh);
    position: absolute;
    background-color: #3e40c5;
    border: calc(0.2vw + 0.2vh) solid #34105a;
    color: white;
    width: calc(2.5vw + 2.5vh);
    height: calc(2.5vw + 2.5vh);
    box-shadow: 0.3vmin 0.3vmin 0.5vmin rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.5vw + 0.5vh);
    text-align: center;
    transition: transform 0.2s;
  }
  
  .homeButton:hover {
    transform: scale(1.1);
  }

.graphContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: rgba(139, 69, 19, 0.8);
  border: 4px solid #5A3310;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden; /* Add this to prevent content from spilling out */
}