.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #4b0082, #8a2be2);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .gameBoard {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .card {
    width: 80px;
    height: 80px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card.flipped {
    background-color: #fff;
    transform: rotateY(180deg);
  }
  
  .gameInfo {
    font-size: 1.5vw;
    margin-bottom: 10px;
  }
  
  .gameWon {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .resetButton, .startButton {
    font-size: 1.5vw;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resetButton:hover {
    background-color: #45a049;
  }

  .gameBoard {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .card {
    aspect-ratio: 1 / 1;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .gemImage {
    width: 80%;
    height: 80%;
    object-fit: contain;
    transform: rotateY(180deg);
  }