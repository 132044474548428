.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #4b0082, #8a2be2);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    border-radius: 15px;
    width: 80%;
    max-width: 600px;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 20px;
  }
  
  .gameContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .clue {
    font-size: 1.5vw;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .wordArea {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .letter, .emptyLetter {
    font-size: 2vw;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
  
  .jumbledArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .letterButton {
    font-size: 1.5vw;
    width: 40px;
    height: 40px;
    margin: 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .letterButton:hover {
    background-color: #45a049;
  }
  
  .gameInfo {
    font-size: 1.5vw;
    margin-top: 20px;
  }
  
  .gameOver {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .resetButton, .startButton{
    font-size: 1.5vw;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .resetButton:hover {
    background-color: #45a049;
  }

  .letterButton {
    /* existing styles */
    background-color: #4CAF50;
    color: white;
    transition: background-color 0.1s, color 0.1s;
  }
  
  .correct {
    background-color: blue;
    color: white;
    cursor: not-allowed;
  }
  
  .incorrect {
    background-color: red;
    color: white;
  }
  
  .disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }