.gameContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    border: 2vh solid black;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 2s ease;
  }
  
  .gameContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }