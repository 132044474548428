.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: rgba(53, 73, 206, 0.9);
  padding: 2%;
  border-radius: 3%;
  text-align: center;
  color: white;
  width: 60%;
  max-width: 60%;
  max-height: 80%;
  font-size: 1.5vw;
  border: 1vh solid #34105a;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
  
  .modalContent h2 {
    margin-top: 0;
    font-size: 2.5vw;
  }
  
  .modalContent button {
    margin-top: 5%;
    padding: 2% 4%;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3%;
    cursor: pointer;
    font-size: 1.5vw;
  }
  
  .modalContent button:hover {
    background-color: #45a049;
  }
  
  .rewardContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5%;
    margin: 4% 0;
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 5px solid #34105a;
  }
  
  .rewardImage {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 60%;
  }
  
  .imageText {
    text-align: center;
    margin-top: 5px;
  }
  
  .imageText p {
    margin: 0;
    font-size: 1vw;
  }
  
  .goldContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: auto;
    padding: 2%;
    border: 5px solid #34105a;
  }
  
  .goldImage {
    width: 10%; /* Adjust this size to make it larger */
    height: auto;
    border-radius: 10%;
  }
  
  .goldText p {
    font-size: 1.8vw; /* Adjust this size to make the text larger */
    margin: 5px 0 0 0;
  }
  