.puzzleContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: linear-gradient(to bottom, #00008b, #000);
    border: 5px solid #fff;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
  }
  
  .puzzleContainer.visible {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  
  .title {
    font-size: 3vw;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 1.5vw;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .canvas {
    border: 2px solid #fff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .input {
    font-size: 2vw;
    padding: 5px;
    margin-bottom: 10px;
    width: 80%;
  }
  
  .button {
    font-size: 2vw;
    padding: 5px;
    cursor: pointer;
    width: 50%;
  }
  
  .correct, .incorrect {
    font-size: 2vw;
    text-align: center;
    margin-top: 20px;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }

  .timer {
    font-size: 2vw;
    margin-bottom: 10px;
    color: #ff0;
  }

